<template>
  <div class="product-wrap product-irrigation">
    <Header is-active="product" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-irrigation"></div>
    <div class="white-wrap">
      <div class="txt-box align-ct pd-5 no-indent">
        <p>智慧灌区系统集成灌溉和信息一体化，运用先进的大数据采集、云端传输和实时响应处理手段。 </p>
        <p>优化水资源配置，多元化信息采集并结合移动端APP构建了一套完善的灌溉工程体系，</p>
        <p>解决了灌区运行管理中的各类问题。</p>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>核心功能</h4></div>
        <div class="customized-box irrigation-customized  mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-ditu"></i></div>
            <h6>地图展示</h6>
            <div class="txt-info">整合GIS数据平台以及二三维地图模型立体展示</div>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-fxyj"></i></div>
            <h6>预警管理</h6>
            <div class="txt-info">实时记录并监测水情信息自动发布预警，响应迅速</div>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-shoufei"></i></div>
            <h6>水费管理</h6>
            <div class="txt-info">制定水费征收管理制度数字化合理化征收水费</div>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-xunjian"></i></div>
            <h6>巡检管理</h6>
            <div class="txt-info">设立周期巡查任务，提高安全意识，减少安全隐患</div>
          </div>
        </div>
        <div class="customized-box irrigation-customized mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-ditu"></i></div>
            <h6>日常工作</h6>
            <div class="txt-info">方便快捷查看当日工作提高工作效率</div>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-sxt"></i></div>
            <h6>视频监控</h6>
            <div class="txt-info">高清视频设备7x24小时监控灌区和设备情况</div>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-gongcheng"></i></div>
            <h6>工程管理</h6>
            <div class="txt-info">查看管理水利，防汛工程工期，简介，推广</div>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-yjya"></i></div>
            <h6>预案管理</h6>
            <div class="txt-info">针对可能发生的灾害，制定相关方案，降低灾害损失</div>
          </div>
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-big align-ct"><h4>系统架构</h4></div>
        <div class="txt-box align-ct">
          <p>灌区信息化系统通过传感器（如水位计、流量计、闸位计、水质传感器、综合气象等装置）进行水利信息采集，</p>
          <p>利用计算机局域网、GPRS、2G/3G/4G、ZigBee、LoRa、VHF、有线通信网等进行监测点、监控中心和灌区信息中心之间的信息传输，</p>
          <p>实现灌区信息采集、传输、处理，形成综合数据库。同时，实现灌区内水雨情信息、水质信息、墒情、闸位、气象信息的实时监测，</p>
          <p>以及各水利机电设施的自动控制（如灌区各配水点的闸位、水源站的电机泵阀及闸门等装置进行远程控制和管理）。实现灌区业务管理，</p>
          <p>包括水量调度、水费征收和日常办公管理。系统架构图如下。</p>
        </div>
        <div class="auto-img mt-5">
          <img src="../../assets/img/product/irrigation/pic_1.png" alt="智慧灌区系统">
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>系统优势</h4></div>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :span="10">
            <el-button type="primary" class="irrigation-ys">开放式结构</el-button>
          </el-col>
          <el-col :span="10">
            <el-button type="primary" class="irrigation-ys">大容量数据存储空间</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :span="10">
            <el-button type="primary" class="irrigation-ys">时段化处理</el-button>
          </el-col>
          <el-col :span="10">
            <el-button type="primary" class="irrigation-ys">工业级设计</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :span="10">
            <el-button type="primary" class="irrigation-ys">GIS模块</el-button>
          </el-col>
          <el-col :span="10">
            <el-button type="primary" class="irrigation-ys">灵活的硬件配置</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :span="10">
            <el-button type="primary" class="irrigation-ys">可拓展性</el-button>
          </el-col>
          <el-col :span="10">
            <el-button type="primary" class="irrigation-ys">抗干扰性</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :span="10">
            <el-button type="primary" class="irrigation-ys">稳定可靠</el-button>
          </el-col>
          <el-col :span="10">
            <el-button type="primary" class="irrigation-ys">故障自动检测</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="fz-big align-lt" style="padding-top: 8%"><h4>系统界面</h4></div>
        <div class="auto-img">
          <img src="../../assets/img/product/irrigation/pic_2.png" alt="系统界面">
        </div>
        <el-row :gutter="10" class="mt-5">
          <el-col :span="12">
            <div class="auto-img">
              <img src="../../assets/img/product/irrigation/pic_3.png" alt="系统界面">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="auto-img">
              <img src="../../assets/img/product/irrigation/pic_4.png" alt="系统界面">
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-big align-lt" style="padding-top: 8%"><h4>定制化开发</h4></div>
              <div class="txt-box dzh-box">
                <p class="em">定制的不仅仅是软件和硬件 更是一种理念</p>
                <p>定制化开发是解决企业个性化难题唯一途径，而且是企业快速发展和提高竞争力的最好方法。</p>
                <el-button type="primary" plain class="zdh-btn" @click="handleShow">立即咨询</el-button>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic dzh-pic"></div>
          </el-col>
        </el-row>
        <div class="fz-sm align-ct" style="padding-top: 4%"><h5>定制化特点</h5></div>
        <div class="customized-box">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-gps"></i></div>
            <p>针对性强</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-bianjie"></i></div>
            <p>操作便捷</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-price"></i></div>
            <p>性价比高</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-service"></i></div>
            <p>服务周到</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-upgrade"></i></div>
            <p>升级方便</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop ref="backTop"></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧灌区系统-产品中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧灌区系统-产品中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {
    handleShow () {
      this.$refs.backTop.handleOpen()
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.product-irrigation {
  .banner-irrigation {
    background: url("../../assets/img/product/irrigation/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }

  .irrigation-customized {
    h6 {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1890FF;
    }

    .txt-info {
      width: 80%;
      margin: 0 auto;
      font-size: 16px;
      font-family: SimSun;
      font-weight: 400;
      color: #595959;
      line-height: 1.5;
    }
  }

  .irrigation-ys {
    width: 290px;
    margin-top: 5%;
  }
}

@media (max-width: 1000px) {
  .product-irrigation {
    .irrigation-customized {
      h6 {
        font-size: 20px;
      }

      .txt-info {
        font-size: 16px;
      }
    }

    .irrigation-ys {
      width: 250px;
      margin-top: 5%;
    }
  }
}

@media (max-width: 850px) {
  .product-irrigation {
    .irrigation-customized {
      h6 {
        font-size: 16px;
      }

      .txt-info {
        font-size: 12px;
      }
    }

    .irrigation-ys {
      width: 200px;
      margin-top: 5%;
    }
  }
}
</style>
